const data = {
  name: "Paul Mandel",
  email: "paul@mand3l.com",
  phone: "831-359-6122",
  city: "San Francisco",
  tagline: "Product excellence through holistic design leadership",
  sections: [{
    name: "Experience",
    items: [
    {
      summary1: "Canvas",
      summary2: "UX/UI Manager",
      summary3: "Collaborative robots to speed up drywall finishing",
      dates: 'Jun 2021 – Aug 2022',
      bullets: [
        "Managed team of six designers and engineers working across software and hardware.",
        "Implemented UX process and design thinking throughout product and eng teams.",
        "Improved operator satisfaction through successful redesign of on-machine interface.",
      ]
    },
    {
      summary1: "Google Nest",
      summary2: "Staff UX Engineer",
      summary3: "Improving Google-wide hardware product design",
      dates: "Nov 2020 – May 2021",
      bullets: [
        "Established hardware design as recognized UX Engineering sub-specialty.",
        "Developed hardware-centric prototyping tools used across Google.",
        "Proposed and drove interdisciplinary alignment on LED development playbook.",
      ],
    }, {
      summary1: "Google Nest",
      summary2: "Senior UX Engineer",
      summary3: "Prototyping to improve product design process",
      dates: "Dec 2018 – Oct 2020",
      bullets: [
        "Managed prototyping efforts for thermostats, Assistant-enabled speakers/displays.",
        "Led design of physical interface for Nest Thermostat and Nest Audio (shipped 2020).",
        "Established in-office maker space to engage design team with hardware development.",
      ],
    }, {
      summary1: "Amazon Lab126",
      summary2: "Senior Design Technologist",
      summary3: "Alexa hardware concept prototyping",
      dates: "Apr 2016 – Nov 2018",
      bullets: [
        "Led early-stage concept development for robotic Echo Show 10, Echo Dot w/ Clock.",
        "Led team to design/build standard prototyping platform for unreleased product.",
      ],
    }, {
      summary1: "Amazon Lab126",
      summary2: "Design Technologist",
      summary3: "Alexa hardware concept prototyping",
      dates: "Oct 2014 – Apr 2016",
      bullets: [
        "Designed and built first functional prototype of Echo Show product concept.",
        "Worked closely with design, engineering to improve product development processes.",
      ],
    }, {
      summary1: "Leap Motion",
      summary2: "Software Engineer/Design Lead",
      summary3: "Gesture design for novel interface",
      dates: "Sep 2013 – Aug 2014",
      bullets: [
        "Led 6-person design/prototyping team to improve desktop gesture controls.",
        "Established user-testing program, drove user-centric values across engineering team.",
      ],
    }, {
      summary1: "MHCI Capstone Project",
      summary2: "Research Lead",
      summary3: "Developmental math education",
      dates: "Jan 2013 – Aug 2013",
      bullets: [
        "Identified opportunities to improve market fit of flagship product.",
        "Coordinated extensive, distributed human-centered research process.",
      ],
      hidden: true,
    }, {
      summary1: "Rethink Robotics",
      summary2: "Software Engineering Contractor",
      summary3: "Manufacturing robots",
      dates: "May 2012 – Aug 2012",
      bullets: [
        "Developed full range of automated manufacturing acceptance tests.",
        "Helped design high-level robust behavioral control systems.",
      ],
    }]
  }, {
    name: "Education",
    items: [{
      summary1: "Carnegie Mellon University",
      summary2: "Master of Human Computer Interaction (MHCI)",
      dates: "Graduated Aug 2013",
      bullets: [
        "Specialized in novel user interfaces and human-robot interaction.",
        "Worked with head of Robotics Institute to drive user-centric robotics research.",
      ],
    }, {
      summary1: "Olin College of Engineering",
      summary2: "B.S. in Engineering, Robotics & Control",
      dates: "Graduated May 2009",
      bullets: [
        "Developed meter-long robotic tuna fish for senior capstone."
      ]
    }]
  }, {
    name: "Misc",
    items: [{
      summary1: "Sundown Blues",
      summary2: "Vice President, Board Member, Events Coordinator",
      summary3: "501(c)(3)",
      dates: "Feb 2014 – Sep 2018",
      bullets: [
        "Lead team of 15 staff, 25 volunteers to organize yearly 200-person conference.",
        "Automated time-consuming scheduling and schedule-communication processes.",
        "Led development of first open-source comprehensive blues dance curriculum.",
      ],
    }, {
      summary1: "International Blues Dance Instructor",
      dates: "Aug 2010 – Sep 2018",
      bullets: [
        "Professionally taught and competed in social blues dancing around the world.",
        "Comfortable in front of crowds, managing large groups in chaotic environments.",
      ],
    }]
  }, {
    name: "Skills",
    items: [{
      text: [
        "Proficient at leading interdisciplinary teams, communicating with leadership and cross-functional partners",
        "Experienced in user-centric design, qualitative research, product strategy, hardware/software prototyping",
        "Fluent in modern product development practices including agile, retrospectives, six-sigma, PDP",
        "Highly creative, excellent under pressure and plays well with others",
      ],
    }]
  }]
}


export default data
